* {
  font-family: "Sarabun", sans-serif;
}
body {
  font-family: "Sarabun", sans-serif !important;
}
a {
  text-decoration: none !important;
  color: #101010 !important;
  &.link {
    color: #0d6efd !important;
    text-decoration: underline !important;
  }
}
.p-100 {
  padding-top: 100px;
  @media screen and (max-width: 420px) {
    padding-top: 70px;
  }
}
.p-60 {
  padding: 60px 0;
  @media screen and (max-width: 420px) {
    padding: 30px 0;
  }
}
.logo-img {
  height: 55px;
  @media screen and (max-width: 420px) {
    height: 45px;
  }
}
.bluebtn {
  background: #2f80ed !important;
  border-radius: 10px !important;
  width: auto !important;
  box-shadow: none !important;
  padding: 12px 30px !important;
  font-size: 17px !important;
  line-height: 21px !important;
  color: #fff !important;
  height: 54px !important;
  font-weight: 300 !important;
  text-transform: capitalize !important;
}
.logouticon {
  min-width: 30px !important;
}

.menucolor {
  color: #2f80ed !important;
}
.mobile-userprofile {
  color: #000 !important;
  background-color: #edf1f8 !important;
  width: 54px !important;
  min-width: 54px !important;
  height: 54px !important;
  font-size: 18px !important;
  line-height: 23px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  display: flex !important;
  padding: 23px !important;
  margin: auto !important;
}
.username {
  text-align: center !important;
}
.create-account-page {
  .content {
    height: 100vh !important;
    overflow: auto !important;
    .form-section {
      margin-top: 50px;
    }
  }
}

.loginpage {
  height: 100vh;
  .imgside {
    width: 50%;
    position: relative;
    @media screen and (max-width: 420px) {
      display: none;
    }
    img {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
    .imgcontent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
      width: 500px;
      h1 {
        color: #fff;
        font-size: 40px;
        line-height: 52px;
        font-weight: 700;
      }
      p {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        font-weight: 200;
      }
    }
  }
  .content {
    width: 50%;

    @media screen and (max-width: 420px) {
      width: 100%;
      height: 100%;
    }
    // .form-section {
    //   margin-top: 50px;
    // }
  }
}
.bgimg {
  background: url("./images/house.png") no-repeat;
  background-size: cover;
}
.profileopen {
  position: absolute !important;
  right: 0 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.25rem !important;
  color: #000 !important;
  width: 150px !important;
}
.userprofile {
  color: #000 !important;
  background-color: #edf1f8 !important;
  width: 54px !important;
  min-width: 54px !important;
  height: 54px !important;
  font-size: 18px !important;
  line-height: 23px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  display: flex !important;
  padding: 23px !important;
  margin-left: 38px !important;
  .avtarimg {
    background-color: #edf1f8 !important;
    color: #000;
  }
}
.searchfield {
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 70%;
  position: relative;
  margin: auto;
  .custom-input {
    height: 60px;
    border-radius: 10px;
    border: 0;
    // text-indent: 25px;
    // border: 2px solid #d6d4d4;
    @media screen and (max-width: 420px) {
      height: 50px;
    }
    &:focus {
      box-shadow: none;
      border: 0;
    }
  }
  .custom-input1 {
    height: 60px;
    border-radius: 10px 10px 0 0;
    border: 0;
    // text-indent: 25px;
    // border: 2px solid #d6d4d4;
    &:focus {
      box-shadow: none;
      border: 0;
    }
  }
  .serachbtn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    // width: 110px;
    // background: blue;
    &.bluebtn {
      height: 50px !important;
    }
  }
  .suggestion-box {
    background: #ffffff;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
    border-radius: 0 0 10px 10px;
    width: 100%;
    position: absolute;
    margin: auto;
    margin-top: 0px;
    text-align: left;
    padding: 10px;
    z-index: 1;
  }
}
.suggestion-box {
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 70%;
  position: relative;
  margin: auto;
  margin-top: 10px;
  text-align: left;
  padding: 10px;
  .suggestion {
    border-bottom: 1px solid #d3d3d3;
    padding: 5px 0;
    margin-bottom: 6px;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}
// .searchfield{
//     background: #FFFFFF;
//     box-shadow: 0px 8px 40px rgba(0 ,0,0,0.08 );
//     border-radius: 10px;
//     width: 70%;
//     fieldset{
//         border:0 !important
//     }

// }
.searchfield .MuiOutlinedInput-input {
  border: 0 !important;
}
.home-banner {
  background: url(./images/home-banner.png),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) no-repeat;
  background-blend-mode: overlay;
  background-size: 100% 100%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.about-us-banner {
    height: 500px;
  }
  .home-content {
    h1 {
      font-weight: 600;
      font-size: 48px;
      line-height: 62px;
      color: #ffffff;
    }
    p {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      font-weight: 200;
      margin-bottom: 50px;
    }
  }
}
.about-us {
  p,
  li {
    color: #7f7f7f;
    font-size: 16px;
    line-height: 32px;
  }
}
.title-box {
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: #101010;
    margin-bottom: 60px;
  }
}
.work-section {
  .box {
    padding: 0 30px;
    h3 {
      font-size: 26px;
      line-height: 33px;
      color: #101010;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      color: #666666;
    }
  }
}
.loadertext {
  display: block;
  width: 100%;
}
.adu-plan-section {
  .plan-main-box {
    display: flex;
    .infinite-scroll-component__outerdiv {
      width: 100%;
      .infinite-scroll-component {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        overflow-x: hidden !important;
      }
    }

    .plan-saved-grid {
      width: 33.3333%;
      padding-right: 20px;
      &:nth-child(3n + 3) {
        padding-right: 0;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 0;
      }
    }
    .plan-grid {
      width: 50%;
      padding-right: 20px;
      &:nth-child(2n) {
        padding-right: 0;
      }
      @media screen and (max-width: 768px) {
        width: 50%;
        padding-right: 10px;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
  .planbox {
    position: relative;
    margin-bottom: 40px;
    .liked {
      position: absolute;
      background: #fff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      text-align: center;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: auto;
      }
    }
    .img-box {
      height: 300px;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        // position: absolute;
        // top: 0;
        // left: 0;
      }
    }

    .content {
      margin-top: 15px;
      h4 {
        font-weight: 600;
      }
      .price {
        color: #2f80ed;
      }
      p {
        font-size: 18px;
        line-height: 23px;

        color: #666666;
      }
    }
  }
  .liked {
    position: absolute;
    background: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: auto;
    }
  }
}
footer {
  background-color: #0e2e58;
  color: #fff;
  .footer-content {
    padding: 30px 0;
    h1 {
      font-size: 38px;
      line-height: 49px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: 200;
      margin-bottom: 40px;
    }
  }
}
.bottom-footer {
  background-color: #0a2a55;
  padding: 20px 0;
  color: #d9dbe1;
  a {
    color: #d9dbe1 !important;
  }
  .footer-nav {
    ul {
      padding: 0;
      list-style: none;
      text-decoration: none;
      display: flex;
      margin-bottom: 0;

      li {
        margin-right: 30px;
        a {
          color: #d9dbe1 !important;
          text-decoration: none;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}
.form-section {
  // margin-top: 50px;
  a {
    color: #103d91 !important;
    text-decoration: none !important;
  }
  .form-group {
    margin-bottom: 20px;
    position: relative;
    .form-control {
      height: 50px;
      background: #fdfdfd;
      background-color: #fdfdfd;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 5px;
      &:focus {
        box-shadow: none;
        border-color: #ced4da;
      }
      &.phoneinput {
        padding-left: 85px;
      }
    }
    label {
      margin-bottom: 10px;
    }
    .hide-show {
      position: absolute;
      top: 45px;
      right: 16px;
      cursor: pointer;
    }
    select {
      position: absolute;
      top: 34px;
      left: 0;
      background: #fdfdfd;
      width: 70px;
      height: 50px;
      display: block;
      z-index: 1;
      border-radius: 5px 0 0 5px;
      padding: 5px;
      border: 1px solid #eeeeee;
      &:focus {
        outline: none;
        border: 1px solid #eeeeee;
        box-shadow: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .PhoneInput {
    width: 100%;
    height: 50px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #212529;
    // background-color: #EEEEEE;
    background-clip: padding-box;
    border: 1px solid #eeeeee;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    input {
      border: 0;
      padding-left: 50px;
      &:focus {
        outline: none !important;
      }
    }
    .PhoneInputCountry {
      background: #fdfdfd;
      border-right: 1px solid #eeeeee;
      border-radius: 5px 0 0 5px;
      padding: 7px;
      position: absolute;
      left: 0;
      height: 100%;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .bluebtn {
    width: 100% !important;
  }
}

.find-plan {
  .searchfield {
    width: 100% !important;
  }
  .planbox {
    .img-box {
      height: 300px;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        // position: absolute;
        // top: 0;
        // left: 0;
      }
      .liked {
        position: absolute;
        background: #fff;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        text-align: center;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: auto;
        }
      }
    }
    .content {
      margin-top: 15px;
      h4 {
        font-weight: 600;
      }
      .price {
        color: #2f80ed;
      }
      p {
        font-size: 18px;
        line-height: 23px;

        color: #666666;
      }
    }
  }

  .search-filter {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    padding: 40px 35px;
    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;

      color: #101010;
      &.reset {
        color: #2f80ed;
        cursor: pointer;
      }
    }
    .filter-btn {
      background-color: #fdfdfd;
      border: 1px solid #eeeeee;
      border-radius: 15px;
      padding: 10px 15px;
      font-size: 17px;
      line-height: 20px;
      color: #101010;
      font-weight: 400;
      margin-right: 15px;
      margin-bottom: 12px;
      text-transform: capitalize;
      &.activeblue {
        background-color: #2f80ed;
        color: #fff;
        border-radius: 15px;
        padding: 10px 15px;
        font-size: 17px;
        line-height: 20px;
        color: #101010;
        font-weight: 400;
        margin-right: 15px;
        margin-bottom: 12px;
        text-transform: capitalize;
      }
    }
    .activeblue {
      background-color: #2f80ed;
      color: #fff !important;
      border-radius: 15px;
      padding: 10px 15px;
      font-size: 17px;
      line-height: 20px;
      color: #101010;
      font-weight: 400;
      margin-right: 15px;
      margin-bottom: 12px;
      text-transform: capitalize;
    }
    .bluebtn {
      width: 100% !important;
      &:hover {
        background: #fdfdfd !important;
        border: 1px solid #2f80ed !important;
        color: #2f80ed !important;
      }
    }

    .form-group {
      margin-bottom: 20px;
      label {
        margin-bottom: 10px;
      }
      .form-select {
        background-color: #fdfdfd;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
        height: 50px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
.product-detail {
  margin-top: 50px;
  .product-content {
    width: 50%;
    padding: 40px 0;
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      color: #2f80ed;
    }
    p {
      font-size: 18px;
      line-height: 18px;
      color: #666666;
    }
    .desc {
      margin-top: 20px;
      p {
        font-size: 18px;
        line-height: 30px;
        color: #666666;
      }
    }
  }
  .bluebtn {
    padding: 12px 50px !important;
  }
  .image-gallery-content {
    .image-gallery-slide {
      .image-gallery-image {
        height: auto !important;
        max-height: 550px !important;
        object-fit: cover;
      }
    }
  }
  .image-gallery-left-nav {
    .image-gallery-svg {
      height: 30px !important;
      width: 30px !important;
    }
    left: 20px !important;
    padding: 10px !important;
    border-radius: 50% !important;
    background: #c9c5c5 !important;
    opacity: 0.7 !important;
  }
  .image-gallery-right-nav {
    .image-gallery-svg {
      height: 30px !important;
      width: 30px !important;
    }
    right: 20px !important;
    padding: 10px !important;
    border-radius: 50% !important;
    background: #c9c5c5 !important;
    opacity: 0.7 !important;
  }
  .image-gallery-icon {
    filter: none !important;
  }
  .image-gallery-thumbnails {
    padding: 20px 0;
  }
  .image-gallery-thumbnail {
    width: 150px;
    height: 115px;
    border-radius: 5px;
    overflow: hidden;
    border: 0 !important;
    &.active {
      // border: 2px solid #2F80ED;
      border: 0 !important;
    }
    &:hover {
      border: 0 !important;
      outline: none !important;
      // transform: none;
    }
    .image-gallery-thumbnail-image {
      object-fit: cover !important;
      height: 115px;
    }
    + .image-gallery-thumbnail {
      margin-left: 20px;
    }
  }
}
.done-modal {
  .bluebtn {
    width: 100% !important;
  }
  h1 {
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: 500;
  }
}
.help-modal {
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .bluebtn {
    width: 45% !important;
    &:hover {
      background-color: #fff !important;
      border: 2px solid #2f80ed !important;
      color: #2f80ed !important;
    }
  }
}
.menuButton1 {
  &:hover {
    background-color: none !important;
  }
}
.record {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
}
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
}
@media screen and (max-width: 900px) {
  .bottom-footer {
    .footer-nav {
      ul {
        justify-content: center;
        margin-bottom: 15px;
      }
    }
    .bottom-section {
      justify-content: center !important;
    }
  }
}
.location {
  margin-bottom: 30px;
  p {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 420px) {
  .title-box {
    h2 {
      margin-bottom: 30px;
    }
  }
  .home-banner {
    height: 400px;
    &.about-us-banner {
      height: 350px;
    }
    .home-content {
      h1 {
        font-size: 32px;
        line-height: 50px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .searchfield {
    width: 100%;
    .serachbtn {
      &.bluebtn {
        height: 40px !important;
      }
    }
    input {
      height: 50px;
    }
  }
  .work-section {
    .box {
      margin-bottom: 40px;
    }
  }
  .adu-plan-section {
    .planbox {
      margin-bottom: 15px;
    }
  }
  footer {
    .footer-content {
      h1 {
        font-size: 36px;
        line-height: 43px;
      }
    }
  }
  .bottom-footer {
    .footer-nav {
      ul {
        li {
          margin-right: 20px;
          a {
            font-size: 17px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .bottom-section {
      justify-content: center !important;
    }
  }
  .find-plan {
    .search-filter {
      padding: 20px;
    }
  }
  .privacy-page {
    &.p-60 {
      padding: 60px 0;
    }
  }
  .product-detail {
    .product-content {
      width: 100%;
    }
  }
}
